import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const DriveTypesRequiredItemsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/drive-types-required-items",
    component: React.lazy(() => import("./drive-types-required-items")),
  },
];
